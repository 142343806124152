import theme from "~providers/muiThemeProvider/mui-theme";
import { Column } from "~src/common/components/flexs/Column";

import { Body1 } from "../typography/Body1";

import styled from "@emotion/styled";
import HelpIcon from "@mui/icons-material/Help";

interface WrapperProps {
  direction: "column" | "row";
}
export const Sc = {
  Wrapper: styled(Column)<WrapperProps>`
    width: 100% !important;
    flex-direction: ${({ direction }) => direction};
  `,
  TooltipIcon: styled(HelpIcon)`
    color: ${theme.palette.grey[500]};
    transition: color 0.5s;
    cursor: pointer;
    position: relative;
    top: -4px;
    &.MuiSvgIcon-root {
      font-size: 18px;
    }

    :hover {
      color: ${theme.palette.primary[500]};
    }
  `,
  Label: styled(Body1)`
    color: ${theme.palette.grey[500]};
  `,
  ObligationSign: styled(Body1)`
    color: ${theme.palette.error.main};
  `,
  Content: styled.div({
    width: "100%",
  }),
};
