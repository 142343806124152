import { styled, TextField } from "@mui/material";

interface Props {
  isSmall?: boolean;
}
export const StyledTextFieled = styled(TextField)<Props>(({ theme, isSmall = false }) => ({
  width: isSmall ? "200px" : "100%",
  borderRadius: "12px",

  ".MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.main,
    transition: "border-color 0.3s ease-out",
  },
  ":hover .MuiOutlinedInput-notchedOutline": {
    border: `2px solid ${theme.palette.primary.main} !important`,
  },

  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
}));
