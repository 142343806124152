import { isDefined } from "~utils/boolean-utils";
import type { Nullable } from "~utils/types";

import CustomTooltip from "../customTooltip";
import { Row } from "../flexs/Row";

import { Sc } from "./LabelWithInput.style";

import type { FC, PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  label: string;
  extraLabel?: Nullable<string>;
  isObligatory?: boolean;
  direction?: "column" | "row";
  tooltip?: Nullable<string>;
}
export const LabelWithInput: FC<Props> = ({
  label,
  extraLabel = null,
  direction = "column",
  isObligatory = true,
  tooltip = null,
  children,
}) => {
  return (
    <Sc.Wrapper direction={direction}>
      <Row columnGap={8}>
        <Sc.Label value={label} />
        {isObligatory && <Sc.ObligationSign value={"*"} />}
        {isDefined(tooltip) && (
          <CustomTooltip arrow placement="top" title={tooltip}>
            <Sc.TooltipIcon />
          </CustomTooltip>
        )}
        {isDefined(extraLabel) && <Sc.ObligationSign value={extraLabel} />}
      </Row>
      <Sc.Content>{children}</Sc.Content>
    </Sc.Wrapper>
  );
};
