export enum PageTitles {
  CATEGORIES = "Categories",
  EMPLOYEES = "Employees",
  EMPLOYEE_DETAILS = "Employee Details",
  CREATE_EMPLOYEE = "Create Employee",
  FAQS = "FAQs",
  FORGOT_PASSWORD = "Forgot Password",
  ESTABLISHMENT = "Establishment",
  GUESTS = "Guests",
  GUEST_DETAILS = "Guest Details",
  GUEST_CONVERSATIONS = "Guest Conversations",
  GUEST_STAYS = "Guest Stays",
  GUEST_NEEDS = "Guest Needs",
  GUEST_NEED_TASKS = "Guest Need Tasks",
  IMPORT_GUESTS = "Import Guests",
  GUIDES = "Guides",
  STAFF_GUIDES = "Staff Guides",
  LOGIN = "Login",
  NEW_TASK = "New Task",
  NOTIFICATIONS = "Notifications",
  OPERATIONS = "Operations",
  REPORTING = "Reporting",
  SERVICES = "Services",
  CREATE_SERVICE = "Create service",
  SERVICE_DETAILS = "Service Details",
  TASKS = "Tasks",
  TASK_DETAILS = "Task Details",
  TEAM_OPERATIONS = "Team Operations",
  TEAM_OPERATION_DETAILS = "Team Operation Details",
  CREATE_TEAM_OPERATION = "Create Team Operation",
  OPERATION_DETAILS = "Operation Details",
  TEAMS = "Teams",
  UPDATE_PASSWORD = "Update Password",
  WIZARD = "Wizard",
  ZONES = "Zones",
  NOT_FOUND = "Not Found",
  ERROR = "Error",
  FORBIDDEN = "Forbidden",
}
